.eyecatcher {
	&.full-screen-slider {
		margin-bottom: 35px;

		.page-block.collection {
			margin-bottom: 0;
		}

		> .eyecatcher-container {
			@extend .swirl-dots;

			&:after {
				@include media-breakpoint-down(lg) {
					display: none;
				}
			}

			> .container-holder {
				margin-left: 0;
				margin-right: 0;

				> .column {
					padding-left: 0;
					padding-right: 0;
				}
			}
		}

		.owl-item {
			@include media-breakpoint-down(lg) {
				padding-bottom: 50vh;
			}

			.item {
				min-height: 100vh;
				@include media-breakpoint-down(lg) {
					max-height: 50vh;
					min-height: 50vh;
				}

				.owl-caption {
					@extend .green-background;
					max-width: 380px;
					padding: 40px 30px 90px;
					@include media-breakpoint-down(lg) {
						max-width: 100%;
						transform: translateY(60%);
					}

					&:before,
					&:after {
						display: none;
					}

					.owl-title {
						color: $yellow;
						font-size: 48px;
						text-shadow: none;
						line-height: 48px;
					}
				}
			}
		}
	}

	.large {
		margin-bottom: 0 !important;

		.item {
			@include media-breakpoint-down(lg) {
				align-items: flex-start;
			}

			.owl-caption {
				.owl-description {
					@include media-breakpoint-down(lg) {
						display: none;
					}
				}
			}
		}
	}

	.item {
		.owl-caption {
			text-align: left;
			max-width: 500px;

			.owl-title {
				font-size: 48px;
				line-height: 36px;
				margin-bottom: 30px;
				@media(max-width: 991px) {
					font-size: 36px;
				}
			}

			.owl-description {
				font-size: 20px;

				p {
					margin-bottom: 0;
					line-height: 25px;
				}
			}
		}
	}

	&:not(.full-screen-slider) {
		.owl-btn {
			&:hover {
				color: $dark-green !important;
			}
		}
	}

	.nodots {
		.owl-dots {
			display: none;
		}
	}
}


