.btn {
	box-shadow: 0 3px 6px rgba($black, 0.15);
	font-size: 15px;
	font-weight: 700;
	text-transform: uppercase;

	// btn-primary
	&.btn-primary {
		&:hover {
		}
	}

	// btn-outline-primary
	&.btn-outline-primary {
		&:hover {
		}
	}

	// btn-secondary
	&.btn-secondary {
		&:hover {
		}
	}

	// btn-outline-secondary
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// btn-back
// =========================================================================
.btn-back {
	display: inline-block;
	font-size: 15px;
	font-weight: 700;
	text-decoration: none;
	text-transform: uppercase;

	&::before {
		display: inline-block;
	}
}

// btn-down
// =========================================================================
.btn-down {
	@extend .btn;
	@extend .btn-primary;

	&:hover {
		color: $dark-green;
	}
}

.card-btn {
	&:hover {
		color: $dark-green !important;

		&:after {
			right: 5px !important;
		}
	}
}
