// headings

// img & iframe
img {
	max-width: 100%;
	height: auto;
}

iframe {
	max-width: 100%;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

.btn {
	font-family: $headings-font-family;
	color: $dark-green;
}

.container-two-columns .container-holder {
	margin-bottom: 0;
}

@media(min-width: 991px) {
	.collection {
		margin-bottom: 35px;

		&.two-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 50%;
					max-width: 50%
				}
			}
		}

		&.three-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 33.333%;
					max-width: 33.333%;

					.card-image {
						background: #fff;
						padding: 10px 10px 0;
					}
				}
			}
		}

		&.four-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 25%;
					max-width: 25%;
				}
			}
		}

		&.five-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 20%;
					max-width: 20%;
				}
			}
		}

		&.six-columns-block {
			.grid-items {
				> .item {
					flex: 0 0 16.6666666667%;
					max-width: 16.6666666667%;
					padding: 0 9px;
				}
			}
		}
	}
}

.container-two-columns .container-holder > .column {
	&.col-md-8 {
		@extend .col-md-8;
	}

	&.col-md-4 {
		@extend .col-md-4;
	}
}

.nomax-height,
.nomax-height img {
	max-height: none !important;
}

.green-background {
	background: $dark-green;

	@extend .curly-dots;

	h1, h2, h3, h4, h5, h6 {
		color: $yellow;
	}

	p {
		color: #fff;
	}

	.btn,
	.owl-btn {
		color: $yellow;

		&:hover {
			color: $yellow !important;
		}
	}

	.owl-btn {
		&:after {
			@extend .arrow-right;
		}

		&:hover {
			color: $dark-green;

			&:after {
				right: 5px !important;
			}
		}
	}
}


.yellow-background {
	background: $yellow;
	padding-top: 110px;
	padding-bottom: 110px;
	@extend .ace-dots;

	h1, h2, h3, h4, h5, h6 {
		color: $dark-green;
	}

	p {
		color: #000;
	}

	.btn {
		color: $dark-green;
	}
}

h2 {
	color: $dark-green;
	line-height: 1;
}


.btn {
	background: transparent !important;
	box-shadow: none !important;
	border: none !important;
	position: relative;
	padding-right: 60px;
	padding-left: 0;
	font-size: 20px !important;
	text-transform: none !important;
	text-align: left !important;

	&:hover {
		&:after {
			right: -5px;
		}
	}

	&:after {
		@extend .arrow-right;
		display: inline-block;
		position: absolute;
		right: 0;
	}
}

.wysiwyg {
	.btn {
		margin-top: 30px;
	}
}

@media(min-width: 1499px) {
	.container-1400 {
		max-width: 1400px !important;
	}
}

.contentblock {
	.card-image {
		position: relative;

		&:before {
			content: '';
			background-image: $homepage-card-background-image;
			width: 100%;
			height: 20px;
			position: absolute;
			background-size: 300%;
			background-repeat: no-repeat;
			bottom: 10px;
		}
	}

	&.homepage-icons, &.footer-icons, &.homepage-blocks {
		.card-image {
			&:before {
				display: none;
			}
		}
	}
}

.padding-bottom-40 {
	padding-bottom: 40px;
}

a:hover {
	opacity: .7;
}

a:active {
	color: initial !important;
	opacity: .7;
}
