// bg-dark
// =========================================================================
section {
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
	}
}

// lead-section
// =========================================================================
&.accommodation-book .lead-section {
	display: none;
}

.lead-section {
	padding: 6vh 0 2vh;

	.title {
		max-width: 600px;
	}

	.container-one-column {
		.container-holder {
			max-width: 100%;
			text-align: left;
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	.info {
		// accommodation
		.properties {
			@extend .d-flex;
			@extend .flex-wrap;
			@extend .justify-content-center;
			@extend .list-unstyled;

			li {
				margin: 0 15px;
			}

			.icon {
				margin-right: 5px;
			}

			.label {
			}
		}

		// assortiment
		.discount-price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.price {
			.amount {
				font-size: $font-size-lg;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.former-price {
			text-decoration: line-through;

			.amount {
				font-size: $font-size-sm;
			}

			.suffix {
				font-size: $font-size-sm;
			}
		}

		.downloads {
			.label {
			}

			.file {
			}
		}
	}

	&.green-background {
		padding: 11vh 0;
	}
}

// content-section
// =========================================================================
.content-section {
	&:not(:empty) {
		margin: 3vh 0;
	}

	// intro/outro
	.intro,
	.outro {
		max-width: 750px;

		.container-holder {
			text-align: center;
		}
	}

	.intro {
		.container-holder {
			margin-bottom: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	.outro {
		.container-holder {
			margin-top: 30px;

			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	h2 {
		margin-bottom: 30px;
		text-align: center;
	}
}

.homepageicons-section {
	padding: 55px 0;
}


.homepage-blocks {
	.grid-items {
		.card-body {
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			background-color: transparent;
			background-image: $homepage-card-background-image;
			background-repeat: no-repeat;
			background-size: 300%;
			background-position: 0 90%;
			padding: 0 20px 50px;

			h3 {
				color: #fff !important;
				font-size: 36px;
			}

			.card-buttons {
				display: none;
			}
		}
	}
}

section.yellow-background {
	margin-bottom: 25px;
}

.bundle-detail-section {
	@extend .yellow-background;
	margin-bottom: 0 !important;
}

&.bundle-detail .footer {
	margin-top: 0;
}

&.accommodation-book {
	.content-section:last-of-type {
		@extend .yellow-background;
	}
}

&.accommodation-detail {
	.booking-section {
		@extend .yellow-background;
	}
}

&.contact-page {
	.content-section:last-of-type {
		@extend .yellow-background;
	}
}
