> footer.footer {

	background-color: #000;
	margin-top: 60px;
	padding-top: 90px;
	text-align: center;
	position: relative;

	&:before {
		content: '';
		background-image: $homepage-card-background-image;
		background-size: contain;
		position: absolute;
		left: 0;
		top: 20px;
		width: 100%;
		height: 31px;
		@include media-breakpoint-down(lg) {
			background-size: cover;
		}
	}

	> section {
		.logo {
			margin-bottom: 60px;
		}

		&.footer-logos {
			padding: 4vh 0;
		}

		&.footer-content {
			padding: 4vh 0;
		}

		&.footer-links {
			padding: 4vh 0;
		}

		&.footer-socket {
			padding: 1vh 0;
			background: #fff;

			.footer-link .list {
				justify-content: center;

				a {
					color: $dark-green;
				}
			}
		}
	}

	.footer-text {
		margin-bottom: 35px;

		* {
			color: #fff;
		}
	}
}


