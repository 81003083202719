&.home {
	.mini-sab {
		margin-top: -150px;
		@media(max-width: 1599px) {
			padding-bottom: 84px;
		}
		@include media-breakpoint-down(lg) {
			margin-top: -280px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: -295px;
		}
		@include media-breakpoint-down(xs) {
			padding-bottom: 54px;
		}
	}
}

&:not(.home) {
	.mini-sab {
		padding: 0;
		margin-top: -35px;
		background: $yellow;

		> .container {
			z-index: 9001;
		}
	}
}

.mini-sab {
	margin-top: 0;
	padding-bottom: 54px;

	.container {
		position: relative;
		@extend .d-flex;
		@extend .justify-content-center;

		.container-holder {
			z-index: 100;
			position: relative;
			margin: 15px 0 0 0;
			padding: 15px 0;
			border-radius: 4px;
			background: transparent;
			box-shadow: none;
			color: #fff;
			// below eyecatcher
			@include media-breakpoint-up(lg) {
				margin-top: -50px;
				padding-left: 30px;
				padding-right: 30px;
			}
			@include media-breakpoint-down(xl) {
				padding: 0;
			}
			// above eyecatcher
			/* @include media-breakpoint-up(lg) {
				position: absolute;
				left: auto;
				right: auto;
				bottom: 0;
			} */
		}
	}

	.tommy-zeb-person-categories,
	.tommy-zeb-accommodation-types,
	.tommy-zeb-date-begin,
	.tommy-zeb-date-end {
		background: #fff;
		border-radius: 3px;
		@media(min-width: 992px) {
			min-width: 192px;
		}
	}
}

.mini-sab .container .container-holder {
	margin-top: 0;
}
