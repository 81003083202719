.homepage-icons {
	@extend .four-columns-block;
	margin-bottom: 0;

	.item {
		.card {
			display: flex;
			flex-flow: row;
			border: none;
			align-items: center;

			.card-image {
				background: $icon-background;
				background-repeat: no-repeat;
				width: 105px;
				height: 90px;
				margin-right: -25px;

				img {
					position: absolute;
					left: 40%;
					top: 40%;
					transform: translate(-50%, -50%);
				}
			}

			.card-body {
				padding: 0;
				z-index: 9000;
				background: transparent;

				h3 {
					color: #000;
					font-size: 18px;
					font-weight: 800;
				}

				.card-buttons {
					display: none;
				}
			}
		}
	}
}

.footer-icons {
	margin-bottom: 35px;

	> .grid-items {
		display: block !important;

		> .item {
			display: inline-block !important;
			max-width: none !important;
			background: $icon-background;
			background-repeat: no-repeat;
			width: 67px !important;
			height: 52px !important;
			background-size: contain;

			&:not(:last-of-type) {
				margin-right: 15px;
			}
		}
	}

	.card {
		background: transparent;
		border: none;
		align-items: center;
		justify-content: center;

		.card-image {
			background: transparent;

			a.card-image-link {
				font-size: 22px;
				color: #fff;
			}
		}

		.card-body {
			display: none !important;
		}
	}
}

&.contact {
	.form {
		background: #fff;
		border: none;
	}
}

&.service-detail {
	.lead-section {
		.container {
			padding-bottom: 25px;
		}
	}
}
