// override variables after vendors like bootstrap are loaded


$green: #70B744;
$dark-green: #094E42;
$yellow: #FCE28B;
$pink: #F28482;


$arrow-after: '\E800';
$fontello: 'Fontello';

.arrow-right {
	content: url('/images/pijl-groen.svg');
	width: 40px !important;
	right: 10px !important;
	top: 50%;
	transform: translateY(-50%);
}

.green-background {
	.arrow-right {
		content: url('/images/pijl-geel.svg');
	}
}

*:hover > .arrow-right:after {
	right: 5px !important;
}

$green-row-background-image: url('/images/green-row-background.svg');
$yellow-row-background-image: url('/images/yellow-row-background.svg');
$homepage-card-background-image: url('/images/homepage-card-background.svg');
$swirl-dots: url('/images/swirl-dots.svg');

$icon-background: url('/images/homepageicon-background.svg');

.curly-dots {
	position: relative;

	&:before {
		content: '';
		background: $green-row-background-image;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 74px;
		z-index: 1;
		transform: translateY(-50%);
		background-repeat-y: no-repeat;
		background-size: 1000px;
	}

	&:after {
		content: '';
		background: $green-row-background-image;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 74px;
		z-index: 1;
		transform: translateY(50%);
		background-repeat-y: no-repeat;
		background-size: 1000px;
	}
}

.ace-dots {
	position: relative;

	&:before {
		content: '';
		background: $yellow-row-background-image;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 74px;
		z-index: 9000;
		transform: translateY(-50%);
		background-size: 688px;
	}

	&:after {
		content: '';
		background: $yellow-row-background-image;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 36px;
		z-index: 9000;
		background-size: 688px;
		transform: rotate(180deg);
	}
}

.swirl-dots {
	position: relative;

	&:before {
		content: '';
		background: $swirl-dots;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 33px;
		z-index: 9000;
		background-size: contain;
		@include media-breakpoint-down(xl) {
			background-size: cover;
		}
	}

	&:after {
		content: '';
		background: $swirl-dots;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 33px;
		z-index: 9000;
		background-size: contain;
		transform: rotate(180deg);
		@include media-breakpoint-down(xl) {
			background-size: cover;
		}
	}
}
