// variables loaded before loading vendor variables, used to prepare vendor variables like bootstrap

// font
@import url('https://fonts.googleapis.com/css2?family=Averia+Libre:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap');

$font-family-averia: 'Averia Libre', cursive;
$font-family-source-sans: 'Source Sans Pro', sans-serif;


$font-family-base: $font-family-source-sans;
$headings-font-family: $font-family-averia !default;

$font-awesome: 'Font Awesome 5 Pro';

// build options
$use-sticky-header: true;
$use-html-fontsize-reset: true;

// colors
$primary: #70B744;
$secondary: #3d4609;
$tertiary: #488ddb;
$quaternary: #044389;

$dark: #3d4609;

// matrix-colors
$matrix-color-primary: #fce28b;
$matrix-color-secondary: #094E42;
$matrix-color-tertiary: #094E42;
$matrix-color-quaternary: #044389;

/*
$body-bg: #FFF !default;
$body-color: #000 !default;
$body-color--dark: #fff !default;
$link-color: $primary !default;
$link-color--hover: darken($link-color, 15%) !default;

// font
$font-family-base: 'Helvetica', sans-serif !default;

// typography
$font-weight-medium: 500 !default;
$font-weight-semi-bold: 600 !default;
$font-weight-bold: 700 !default;
$font-style-italic: italic;
$font-style-base: normal !default;


$headings-font-style: $font-style-base !default;
$headings-font-weight: $font-weight-bold !default;
$headings-color: $body-color !default;

$color-h1: $body-color !default;
$color-h2: $body-color !default;
$color-h3: $body-color !default;
$color-h4: $body-color !default;
$color-h5: $body-color !default;
$color-h6: $body-color !default;

// grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1500px,
  xxxl: 1820px
) !default;

$grid-breakpoints-small: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1600px,
  xxxl: 1920px
) !default;

$container-max-widths-small: (
  xs: 0,
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1000px,
  xxl: 1300px,
  xxxl: 1600px
) !default;

// header
$header-logo-height: 50px !default;
$header-logo-height--lg: 80px !default;
$header-logo-margin: 20px !default;

// card
$card-bg--dark: #000 !default;

// carousel
$carousel-min_height: 35vh !default;
$carousel-min_height--lg: 70vh !default;
*/
