&.default {
	@media(max-width: 1200px) {
		padding-top: 144px;
	}
}

.header {
	// responsive custom styling
	z-index: 9999;
	@media(max-width: 1200px) {
		position: fixed;
		top: 0;
		width: 100%;
	}

	.container-one-column {
		.container-holder {
			> .column {
				flex-flow: row wrap;
				@include media-breakpoint-down(lg) {
					.page-block {
						&:not(#default_menu) {
							flex: 0 0 50%;
							max-width: 50%;
						}

						&:nth-of-type(1n + 2) {
							text-align: right;
						}

						&:nth-of-type(2n + 2) {
							text-align: left;
						}

						&.secondary-logo {
							position: absolute;
							right: 0;
							top: 50px;
							transform: scale(0.6);
						}

						&.link {
							a {
								color: $green;

								&:before {
									content: '\f0f2';
									font-family: 'FONT AWESOME 5 PRO';
									margin-right: 7px;
								}

								&:after {
									display: none !important;
								}
							}
						}
					}
				}

				.page-block.menu-toggle {
					min-width: 0;
					z-index: 9001;
					position: relative;
				}

				.page-block.logo {
					min-width: 0;
					margin: 0;
				}

				.page-block.site-switcher {
					margin: 0;
					flex-grow: 1;
				}

				// responsive mobile header order.
				.page-block.menu-toggle {
					order: 0;
				}

				.page-block.logo {
					order: 1;
				}

				.page-block.site-switcher {
					order: 2;
				}

				.page-block.menu {
					order: 3;
				}

				// Desktop resets
				@include media-breakpoint-up(xl) {
					.page-block.menu-toggle {
						order: unset;
					}
					.page-block.logo {
						order: unset;

						&:not(.secondary-logo) {
							margin-left: auto;
							margin-right: auto;
							width: 100%;
						}

						&.secondary-logo {
							position: fixed;
							left: 50%;
							transform: translate(-50%, -50%);
							top: 160px;
						}
					}
					.page-block.site-switcher {
						order: unset;
					}
					.page-block.menu {
						order: unset;
					}

					.page-block.site-switcher {
						display: flex;
						justify-content: flex-end;
						margin-left: 25px;
						flex-grow: 0;
					}

					.page-block.menu-toggle {
						margin: 0;
						flex: 0 0 50%;
						max-width: 50%;


						.navbar-toggler {
							color: $green;

							.navbar-toggler-label {
								font-family: $font-family-averia;
								font-weight: 600;
							}
						}
					}
					.page-block.link {
						flex: 0 0 50%;
						max-width: 50%;
						text-align: right;

						a.link {
							font-size: 20px;
							color: $green;
							background: transparent;
							border: none;
							box-shadow: none;

							&:before {
								content: '\f0f2';
								font-family: 'FONT AWESOME 5 PRO';
								margin-right: 7px;
							}

							&:after {
								display: none;
							}
						}
					}
				}
			}
		}

		.column.default {
			transition: .2s;
		}
	}

	&.sticky {
		.column.default {
			height: 80px !important;
		}

		.logo {
			&:not(.secondary-logo) {
				transform: translateY(-80px);
			}

			&.secondary-logo {
				transform: scale(0.5) translate(-100%, -380px) !important;
			}
		}
	}

	&.mobilescrolled {
		.secondary-logo {
			@media(max-width: 991px) {
				transform: scale(.25) translateY(-100%) !important;
			}
		}
	}
}

.navbar-expand-xl .navbar-toggler,
.header [class^="container-"] .column.default .navbar-toggler {
	display: inline-block !important;
}


*[background-color='#F28482'] {
	font-size: 500px !important;
}


#default_menu {
	position: fixed;
	left: 0;
	top: 0;
	padding-top: 60px;
	background: #fff;
	display: flex !important;
	height: 100vh;
	align-items: flex-start;
	z-index: 9000;
	@include media-breakpoint-up(lg) {
		background-image: url('/images/logo2.svg');
		background-repeat: no-repeat;
		background-position: bottom;
	}


	&.show {
		box-shadow: 0 0 20px 2920px #00000040;
	}

	.navbar-nav {
		display: none;
		transition: 50ms !important;
		overflow: auto;
		max-height: 100%;

		a {
			min-width: 400px;
			padding-left: 27px;
			font-size: 17px;
			font-weight: 600;
			height: 61px;
			display: flex;
			align-items: center;

			&[aria-expanded='true'] {
				&:after {
					transform: rotate(180deg);
				}
			}
		}

		.dropdown-menu {
			position: static !important;
			transform: none !important;
			border: none !important;
			background: $dark-green;
			border-radius: 0;

			a {
				color: #fff;
			}
		}
	}
}

@media(max-width: 991px) {
	.header .navbar-toggler {
		color: $green;
	}
}
